module.exports = {
  siteTitle: 'Godfathers of Sex Abuse', // Site title.
  siteTitleAlt: 'The GodFathers of Sex Abuse Site', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://thegodfathersofsexabuse.com', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links.
  siteDescription: 'Godfathers of Sex Abuse', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  consumer_key: 'MWTXIZHecNP19FusLWLumoPmu',
  consumer_secret: 'ORsuhiGQrxZ5AeEPpEQxSOnDAZwineiX3KveAJOSUld5lW61hb',
  bearer_token: 'INSERT_HERE_YOUR_BEARER_TOKEN',
  disqusShortname: 'gatsby-business-starter', // Disqus shortname.
  userName: 'Deana Sacks',
  userTwitter: 'DeanaSacks',
  userLocation: 'Houston TX',
  userDescription: '',
  copyright: 'Copyright © Godfathers of Sex Abuse 2018-2020. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#00d1b2', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
}
