import React, { Component } from 'react'
import Helmet from 'react-helmet'
import Transition from '../components/Transition'
import config from '../../config'
import '../assets/global.css'
import 'typeface-public-sans/index.css'

class Layout extends Component {
  constructor (props) {
    super(props)
    this.state = { isActive: false }
    this.toggleNavbar = this.toggleNavbar.bind(this)
  }

  toggleNavbar () {
    this.setState({ isActive: !this.state.isActive })
  }

  render () {
    return (
      <div id='layout-wrapper'>
        <Helmet>
          <title>{config.siteTitle}</title>
          <meta name='description' content={config.siteDescription} />
        </Helmet>
        <Transition location={this.props.location}>
          {this.props.children}
        </Transition>
      </div>
    )
  }
}

export default Layout
